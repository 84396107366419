import './singlecategory.css'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { Container } from '@mui/system'
import { Box, Button, MenuItem, FormControl, Select } from '@mui/material'
import Loading from '../Components/loading/Loading'
import { BiFilterAlt } from 'react-icons/bi';
import ProductCard from '../Components/Card/Product Card/ProductCard'
import CopyRight from '../Components/CopyRight/CopyRight'
import { useSelector } from 'react-redux';
import {getProductByCat} from '../slices/ProductSlice';
// import {getProductByProdId} from '../slices/SingleProductSlice';
import { API_URL } from "../Const";
import { useDispatch } from 'react-redux';
import styles from '../Components/Card/Product Card/ProductCard.module.css'

import { Card, CardActionArea, CardActions, Rating, CardContent, Typography } from '@mui/material';
import defaultProductImage from "../Assets/Images/default-product-image.png"

const SingleCategory = (dat) => {
    const [productData, setProductData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [filterOption, setFilterOption] = useState('')
    const [title, setTitle] = useState('')
    const { cat } = useParams()
    const productFilter = useSelector((state) => state.products);
    const dispatch = useDispatch();

    useEffect(() => {
        getCategoryProduct()
        window.scroll(0, 0)
    }, [])

    const getCategoryProduct = async () => {
        try {
            setIsLoading(true)
            var catId = window.location.href.split("product/type/")[1];
            await axios.get(API_URL+`viewProducts.php?productCategoryId=${catId}`).then(function (response) {
                dispatch( getProductByCat(response.data));
                setProductData(response.data)
                console.log(response.data);
                setIsLoading(false)
            })

        } catch (error) {
            console.log(error);
        }
    }

    // const getSingleProd = async (da) => {
    //     try {
    //         await axios.get(API_URL+`getProductDetails.php?productId=${da}`).then(function (response) {
    //             dispatch( getProductByProdId(response.data));
    //             console.log(response.data);
    //             setIsLoading(false)
    //         })

    //     } catch (error) {
    //         console.log(error);
    //     }
    // }


    const handleChange = (e) => {
        setFilterOption(e.target.value.split(" ").join("").toLowerCase())
        setTitle(e.target.value)
    }
   

    const loading = isLoading ?
        (
            <Container maxWidth='xl' style={{ marginTop: 10, display: "flex", justifyContent: "center", flexWrap: "wrap", paddingLeft: 10, paddingBottom: 20 }}>
                <Loading /><Loading /><Loading /><Loading />
                <Loading /><Loading /><Loading /><Loading />
            </Container >
        )
        : ""
    return (
        <>
        
            <Container maxWidth='xl' style={{ marginTop: 90, display: 'flex', justifyContent: "center", flexDirection: "column" }}>
                < Box sx={{ minWidth: 140 }}>
                    <FormControl sx={{ width: 140 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, width: "80vw" }}>
                            <Button endIcon={<BiFilterAlt />}>Filters</Button>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={title}
                                sx={{ width: 200 }}
                                onChange={(e) => handleChange(e)}
                            >
                                {productFilter.map(prod => (
                                    <MenuItem key={prod} value={prod}>{prod}</MenuItem>
                                ))}
                            </Select>``
                        </Box>
                    </FormControl>
                </Box>
                {loading}
                <Container maxWidth='xl' style={{ marginTop: 10, display: "flex", justifyContent: 'center', flexWrap: "wrap", paddingBottom: 20, marginBottom: 30, width: '100%' }}>
                    {
                    productData !== undefined && productData.length > 0?
                    productData.map(prod => (
                        <Link   to={`/Detail/type/${cat}/${prod.productDetails.productId}`} state={{ i: prod.productDetails.productId }}  key={prod.productDetails.productId}>
                            <ProductCard prod={prod} />
                        </Link>
                    ))
                    :
                    <Card className={styles.main_card}>
                        <CardActionArea className={styles.card_action}>
                            <Box className={styles.cart_box}>
                                <img alt="image" src={defaultProductImage} loading='lazy' className={styles.cart_img} />
                            </Box>
                        </CardActionArea>
                    </Card >
                    }
                </Container>
            </Container >
            <CopyRight sx={{ mt: 8, mb: 10 }} />
        </>
    )
}


export default SingleCategory