import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const ProductSlice = createSlice({
    name : 'product',
    initialState,
    reducers: {
        getProductByCat(state, action){
            state.push(action.payload);
        }
    }
}
)

export const {getProductByCat} = ProductSlice.actions;
export default ProductSlice.reducer;