import React from 'react'
import styles from './Category.module.css'
import { Link } from 'react-router-dom'

const CategoryCard = ({ data }) => {
    
    return (
        <Link dat = {data} to={`product/type/${data.categoryId}`}>
            <div className={styles.mainCard}>
                <img src={data.categoryImage} alt="" className={styles.mainImg} loading='lazy' />
                <span className={styles.imgTitle}>{data.categoryName}</span>
            </div>
       </Link>
    )
}

export default CategoryCard