import { configureStore } from '@reduxjs/toolkit';
import categoryReducer from './slices/CategorySlice';
import productReducer from './slices/ProductSlice';
import singleProductReducer from './slices/SingleProductSlice';

export const store = configureStore({
    reducer : {
        category: categoryReducer,
        products:productReducer,
        singleProduct : singleProductReducer
    }
});


