import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import "./RandomProducts.css";
import { FaRegHeart } from "react-icons/fa";

import defaultProductImage from "../../../src/Assets/Images/default-product-image.png"

const RandomProductsCard = ({ data }) => {


    useEffect(() => {
console.log(data)
    }, [])
    
    return (
        <div class="card">
            {data.productImages.length > 0 ?
            <img src={data.productImages[0].ProductImageName} alt={data.productName} style={{width:"250px", height:"250px"}}/>
            :
            <img src={defaultProductImage} alt={data.productName} style={{width:"250px", height:"250px"}}/>
             }
             {
                data.productName.length > 20?
                <h4>{data.productName.slice(0, 20)}...</h4>
                :
            <h4>{data.productName}</h4>
             }
            <p class="price-heart">
            <FaRegHeart style={{ fontSize: "22px", color: "grey" }} />
            <p class="price">₹ {data.productRate}</p>
            </p>
            <Link dat = {data} to={`Detail/type/${data.productCategoryId}/${data.productId}`}>
                <p><button>View Product</button></p>
            </Link>
        </div>
    )
}

export default RandomProductsCard