import { Slide } from "@mui/material";
import axios from "axios";
import { forwardRef } from "react";
import {getProductByProdId} from '../slices/SingleProductSlice';
import { API_URL } from "../Const";
import { useDispatch } from 'react-redux';

const getCart = async (setProceed, setCart, authToken) => {
    if (setProceed) {
        var userId = localStorage.getItem('userId');
        const { data } = await axios.get(API_URL+`viewUserCart.php?userId=${userId}`,
            {
                headers: {
                    'Authorization': authToken
                }
            })
        setCart(data);
    }
}
const getWishList = async (setProceed, setWishlistData, authToken) => {
    if (setProceed) {
        var userId = localStorage.getItem('userId');
        const { data } = await axios.get(API_URL+`wishlistSelect.php?userId=${userId}`,
            {
                headers: {
                    'Authorization': authToken
                }
            })
        setWishlistData(data)
    }

}
const handleLogOut = (setProceed, toast, navigate, setOpenAlert) => {
    if (setProceed) {
        localStorage.removeItem('Authorization')
        toast.success("Logout Successfully", { autoClose: 500, theme: 'colored' })
        navigate('/')
        setOpenAlert(false)
    }
    else {
        toast.error("User is already logged of", { autoClose: 500, theme: 'colored' })
    }
}

const handleClickOpen = (setOpenAlert) => {
    setOpenAlert(true);
};

const handleClose = (setOpenAlert) => {
    setOpenAlert(false);
};
const getAllProducts = async (setData) => {
    // try {
    //     const { data } = await axios.get(process.env.REACT_APP_FETCH_PRODUCT);
    //     setData(data)


    // } catch (error) {
    //     console.log(error);
    // }
}

async function GetSingleProduct  (setProduct, id, setLoading){
    // const product = useSelector((state) => state.products);
    // const { data } = await axios.get(`${process.env.REACT_APP_FETCH_PRODUCT}/${id}`)
    const dispatch = useDispatch();
    try {
        console.log("ID " + API_URL+`getProductDetails.php?productId=${id}`)
        await axios.get(API_URL+`getProductDetails.php?productId=${id}`).then(function (response) {
            dispatch( getProductByProdId(response.data));
            setProduct(response.data);
            setLoading(false);
        })

    } catch (error) {
        console.log(error);
    }    

}

async function getProductsBySearch (keyword, setData) {
    try {
        await axios.get(API_URL+`getProductsBySearch.php?keyword=${keyword}`).then(function (response) {
            setData(response.data);
        })

    } catch (error) {
        console.log(error);
    }
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});





export { getCart, getWishList, handleClickOpen, handleClose, handleLogOut, getAllProducts, GetSingleProduct, getProductsBySearch, Transition }