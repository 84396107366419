import { Container, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from 'react-icons/ai';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { getAllProducts, getProductsBySearch } from "../../Constants/Constant";
import axios from "axios";
import { API_URL } from "../../Const";

const SearchBar = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState([]);


    const handleSearch = async (event) => {
        setSearchTerm(event.target.value);
        // getProductsBySearch(event.target.value, setData);
        // const newFilteredData = data.filter(item =>
        //     // (item.name && item.name.toLowerCase().includes(event.target.value.toLowerCase())) ||
        //     // (item.category && item.category.toLowerCase().includes(event.target.value.toLowerCase())) ||
        //     // (item.description && item.description.toLowerCase().includes(event.target.value.toLowerCase()))
        // );
        // setFilteredData(data);
        try {
            await axios.get(API_URL+`getProductsBySearch.php?keyword=${event.target.value}`).then(function (response) {
                setData(response.data);
                console.log(response.data);
            })
    
        } catch (error) {
            console.log(error);
        }
    };


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    return (
        <Container style={{ display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 5 }}>
            <TextField
                id="search"
                type="search"
                label="Search Products"

                value={searchTerm}
                onChange={handleSearch}
                sx={{ width: { xs: 350, sm: 500, md: 800 }, }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <AiOutlineSearch />
                        </InputAdornment>
                    ),
                }}
            />
            {
                searchTerm.length > 0 &&
                <Box sx={{ width: { xs: 350, sm: 500, md: 800 }, overflowY: "scroll", height: "200px" }}>
                    <Stack spacing={0}>

                        {data === undefined || data.length === 0 ?
                            <Typography variant="h6" textAlign="center" margin="25px 0">Product Not Found</Typography>
                            : data.map(products => (
                                <Link to={`/Detail/type/${products.productCategoryId}/${products.productId}`} key={products.productId}>
                                    <Item sx={{ borderRadius: 0, display: 'flex', justifyContent: 'space-between', padding: "2px 15px", alignItems: 'center' }}>
                                        <Typography variant="body2"> {products.productName.slice(0, 35)}</Typography>
                                        {
                                            products.productImages.length > 0?
                                            <img src={products.productImages[0].ProductImageName} alt={products.productName} style={{ width: 55, height: 65 }} />
                                            :
                                            null
                                        }
                                        
                                    </Item>
                                </Link>
                            ))}
                    </Stack>
                </Box>
            }
        </Container >
    )
}

export default SearchBar