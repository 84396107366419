import { Typography } from '@mui/material'
import React from 'react'

const CopyRight = (props) => {
    return (
        <a href='https://zeerovalue.com/' target='_blank' rel='noreferrer' >

            <Typography variant="body1" fontWeight="bold" color="text.secondary" align="center" {...props} style={{ color: '#1976d2', margin: '20px' }}>
                {' '}
                2024
                {' © '}
                Developed By Zeero Value
            </Typography>
        </a>
    )
}

export default CopyRight