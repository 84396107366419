import { Card, CardActionArea, CardActions, Rating, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styles from './ProductCard.module.css'

export default function ProductCard({ prod }) {
    var img = "";
    if(prod.productImages != undefined && prod.productImages[0] != undefined){
        img = prod.productImages[0].ProductImageName;
    }
    
    return (
        <Card className={styles.main_card}>
            <CardActionArea className={styles.card_action}>
                <Box className={styles.cart_box}>
                    <img alt={prod.productDetails.productName} src={img} loading='lazy' className={styles.cart_img} />
                </Box>
                <CardContent>
                    <Typography gutterBottom variant="h6" sx={{ textAlign: "center" }}>
                        {prod.productDetails.productName.length > 20 ? prod.productDetails.productName.slice(0, 20) + '...' : prod.productDetails.productName}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                <Typography variant="h6" color="primary">
                    ₹{prod.productDetails.productRate}
                </Typography>
                <Typography >
                    <Rating precision={0.5} name="read-only" value={"prod.rating"} readOnly />
                </Typography>


            </CardActions>
        </Card >
    );
}