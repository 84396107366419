import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const RandomProductsSlice = createSlice({
    name : 'RandomProducts',
    initialState,
    reducers: {
        getRandomProducts(state, action){
            state.push(action.payload);
        }
        
    }
}
)

export const {getRandomProducts} = RandomProductsSlice.actions;
export default RandomProductsSlice.reducer;