import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = [];

const CategorySlice = createSlice({
    name : 'category',
    initialState,
    reducers: {
        getCategory(state, action){
            state.push(action.payload);
        }
        
    }
}
)

export const {getCategory} = CategorySlice.actions;
export default CategorySlice.reducer;