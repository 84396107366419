import React from "react";
import flogo from "../logo.svg";
import "./FooterTmp.css";
import { Typography } from '@mui/material'

import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

const FooterTmp = () => {
  return (
    <footer>
      <div class="navrow primary">
        <div class="column about">
          <h3 className = "footerh3"><span style = {{color : "red"}}>Archery </span><span style = {{color : "blue"}}>Mart</span></h3>
          <p className = "footerp">
            Company details
          </p>
        </div>
        <div class="column links">
          <h3 className = "footerh3">Quick Links</h3>
          <ul className = "footerul">
            <li className = "footeli">
              <a href="#faq">Enquiry</a>
            </li>
            <li className = "footeli">
              <a href="#support">Support</a>
            </li>
            <li className = "footeli">
              <a href="#support">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div class="column subscribe">
          <h3 className = "footerh3">Subscribe</h3>
          <div>
            <input type="email" placeholder="Your email id here" />
            <button>Subscribe</button>
          </div>
          <div class="social">
            <FaFacebook className = "i" />
            <FaXTwitter className = "i" />
            <FaInstagram className = "i" />
            <FaYoutube className = "i" />
            {/* <i class="fa-brands fa-facebook-square"></i>
            <i class="fa-brands fa-instagram-square"></i>
            <i class="fa-brands fa-twitter-square"></i> */}
          </div>
        </div>
      </div>
      <div class="navrow secondary">
        <div>
          <p className = "footerp">
            <i class="fas fa-phone-alt"></i>
          </p>
          <p className = "footerp">+12 123456789</p>
        </div>
        <div>
          <p className = "footerp"><i class="fas fa-envelope"></i></p>
          <p className = "footerp">mail@domain.com</p>
        </div>
        <div>
          <p className = "footerp"><i class="fas fa-map-marker-alt"></i></p>
          <p className = "footerp">1234 Pearl Street</p>
        </div>
      </div>
      <div class="navrow copyright">
      <a href='https://zeerovalue.com/' target='_blank' rel='noreferrer' >

        <Typography variant="body1" fontWeight="bold" color="text.secondary" align="center" style={{ color: '#1976d2', margin: '20px' }}>
            {' '}
            2024
            {' © '}
            Developed By Zeero Value
        </Typography>
        </a>
        {/* <p className = "footerp">Copyright &copy; 2021 Coding Artist | All Rights Reserved</p> */}
      </div>
    </footer>
  );
};

export default FooterTmp;
