import React, { useEffect } from 'react';
import axios from 'axios';
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CategoryCard from '../../Components/Category_Card/CategoryCard';
import RandomProducts from '../../Components/RandomProducts/RandomProducts';
import Carousel from '../../Components/Carousel/Carousel';
import SearchBar from '../../Components/SearchBar/SearchBar';
import CopyRight from '../../Components/CopyRight/CopyRight';
import { useDispatch } from 'react-redux';
import {getCategory as getCategoryAction} from '../../slices/CategorySlice';
import {getRandomProducts as getRandomProductsAction} from '../../slices/RandomRroductsSlice';
import { useState } from 'react';
import {API_URL} from '../../Const';
import Footer from '../../Footer/FooterTmp'
import ImageSlider from './ImageSlider'
import SimpleImageSlider from "react-simple-image-slider";
// import LandingPage from '../../Components/LandingPage/LandingPage';

const HomePage = () => {
    const dispatch = useDispatch();
    const [cat, setCat] = useState([]);
    const [randomProducts, setRandomProducts] = useState([]);
    const [images,setImage] = useState([]);

    useEffect(() => {
        // GetProductImages()
        getCategory()
        getRandomProducts();
        
        window.scroll(0, 0)
    }, []);

    const getCategory = async() => {
        await axios.get(API_URL+`getProductCategory.php`).then(function (response) {
            dispatch(getCategoryAction(response.data));
            setCat(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getRandomProducts = async() => {
        await axios.get(API_URL+`getRandomProducts.php`).then(function (response) {
            dispatch(getRandomProductsAction(response.data));
            setRandomProducts(response.data);
            console.log(response.data)
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <>
            <Container maxWidth='xl' style={{ display: 'flex', justifyContent: "center", padding: 0, flexDirection: "column", marginBottom: 70 }}>
                <Box padding={1}>
                    <ImageSlider />
                    {/* <LandingPage /> */}
                </Box>
                <Container style={{ marginTop: 90, display: "flex", justifyContent: 'center' }}>
                    <SearchBar />
                </Container>
                <Typography variant='h3' sx={{ textAlign: 'center', marginTop: 10, color: '#1976d2', fontWeight: 'bold' }}>Categories</Typography>
                <Container maxWidth='xl' style={{ marginTop: 90, display: "flex", justifyContent: 'center', flexGrow: 1, flexWrap: 'wrap', gap: 20, }}>
                    {
                        cat.map(data => (
                            <CategoryCard data={data} key={data.categoryImage} />
                        ))
                    }
                </Container>
                <Typography variant='h3' sx={{ textAlign: 'center', marginTop: 10, color: '#1976d2', fontWeight: 'bold' }}>Best Sale</Typography>
                <Container maxWidth='xl' style={{ marginTop: 90,width:"90%", display: "flex", justifyContent: 'center', flexGrow: 1, flexWrap: 'wrap', gap: 20, }}>
                    {
                        randomProducts.map(data => (
                            <RandomProducts data={data} key={data.productId} />
                        ))
                    }
                </Container>
            </Container >
            <Footer style = {{justifyContent : "center"}}/>
            {/* <CopyRight sx={{ mt: 8, mb: 10 }} /> */}
        </ >
    )
}

export default HomePage