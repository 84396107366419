import SimpleImageSlider from "react-simple-image-slider";

import React, { useEffect, useState, useContext } from 'react'
import { API_URL } from "../../Const";
import './imageSlider.css'
import axios from 'axios';
import { Skeleton} from '@mui/material';

export default function ImageSlider() {
    const [images,setImage] = useState([]);
    const [loading, setLoading] = useState(true);
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        GetProductImages()
        window.scroll(0, 0)
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, [])
    async function GetProductImages  (){
        try {
            await axios.get(API_URL+"getCompanyData.php?companyDataName=bannerimages").then(function (response) {
                setImage(response.data);
                setLoading(false);
            })
    
        } catch (error) {
            console.log(error);
        }
    }

  return (
    <>
        {loading ? (
            <Skeleton variant='rectangular' height={400} />
        ) : (
            <div>
            <SimpleImageSlider
            width={"100%"}
            height={504}
            images={images}
            autoPlay = {true}
            autoPlayDelay = {4.0}
            showBullets={true}
            showNavs={true}
            navMargin={10}
            
             />
            </div>
        )}
    </>
  );
}
