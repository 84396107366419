import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const SingleProductSlice = createSlice({
    name : 'prod',
    initialState,
    reducers: {
        getProductByProdId(state, action){
            state.push(action.payload);
        }
    }
}
)

export const {getProductByProdId} = SingleProductSlice.actions;
export default SingleProductSlice.reducer;